import { lazy } from 'react';

const loadPrivacyPolicies = () => import('modules/common/pages/PrivacyPolicies');
export const PrivacyPolicies = lazy(loadPrivacyPolicies);

const loadCookiesPolicies = () => import('modules/common/pages/CookiesPolicies');
export const CookiesPolicies = lazy(loadCookiesPolicies);

const loadTermsConditions = () => import('modules/common/pages/TermsConditions');
export const TermsConditions = lazy(loadTermsConditions);
